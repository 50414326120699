import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"extension-container d-flex flex-column",attrs:{"flat":""}},[_c('router-link',{attrs:{"to":_vm.detailsRoute}},[_c('img',{staticClass:"thumbnail",attrs:{"width":"100%","src":_vm.iconUrl}})]),_c(VCardTitle,{staticClass:"text-subtitle-1 font-weight-bold pt-2"},[_c('router-link',{staticClass:"extension-title",attrs:{"to":_vm.detailsRoute}},[_vm._v(" "+_vm._s(_vm.extension.title)+" ")])],1),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.extension.meta.category)+" ")]),_c(VCardText,{staticClass:"extension-description pb-0"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c(VSpacer),_c(VCardActions,[_c(VSpacer),_c('action-button',{ref:'extension-action-button-' + _vm.extension.meta.baseName,attrs:{"outlined":"","extension":_vm.extension}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }