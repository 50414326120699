import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('app-bar',{staticClass:"app-bar",attrs:{"show-operating-system-selector":_vm.hasOperatingSystemProp,"default-os":_vm.os},on:{"update:os":function($event){_vm.selectedOs = $event}}}),(_vm.extension)?_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c('img',{attrs:{"src":_vm.iconUrl(),"width":"100%"}}),_c('action-button',{staticClass:"my-3",attrs:{"extension":_vm.extension}})],1),_c(VDivider,{staticClass:"mx-5",attrs:{"vertical":""}}),_c(VCol,{attrs:{"cols":"8"}},[_c('div',{staticClass:"text-h3 mb-3"},[_vm._v(" "+_vm._s(_vm.extension.title)+" ")]),_c('div',{staticClass:"text-h6 mb-2"},[_vm._v(" "+_vm._s(_vm.extension.meta.category)+" ")]),_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(" "+_vm._s(_vm.extension.meta.contributors)+" ")]),_c('div',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(" Last update: "+_vm._s(new Date(_vm.extension.updated).toDateString())+"  (Revision: "),_c('a',{attrs:{"href":_vm.revisionUrl()}},[_vm._v(_vm._s(_vm.extension.meta.revision.slice(0, 7)))]),_vm._v(") ")]),_c('div',{staticClass:"text-subtitle-1",domProps:{"innerHTML":_vm._s(_vm.extension.meta.description)}}),_c(VBtn,{staticClass:"my-3 mr-3",attrs:{"outlined":"","href":_vm.extension.meta.homepage}},[_c(VIcon,{staticClass:"pr-1"},[_vm._v(" mdi-home ")]),_vm._v(" View Homepage ")],1),_c(VBtn,{staticClass:"my-3 mr-3",attrs:{"outlined":"","href":_vm.extension.meta.repository_url}},[_c(VIcon,{staticClass:"pr-1"},[_vm._v(" mdi-code-tags ")]),_vm._v(" View Source Code ")],1),(_vm.screenshotsAsList())?_c('div',[_c('div',{staticClass:"text-h6 mb-4"},[_vm._v(" Screenshots ")]),_c(VCarousel,_vm._l((_vm.screenshotsAsList()),function(url,i){return _c(VCarouselItem,{key:i,attrs:{"src":url,"reverse-transition":"fade-transition","transition":"fade-transition"}})}),1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }