
import Vue, { PropType } from 'vue';
import {
  OS, hasExtensionManagerModel,
} from '@/lib/api/extension.service';

interface VueSelectItem {
  text: string;
  value: OS;
  icon: string;
}

export default Vue.extend({
  props: {
    defaultOs: {
      type: String as PropType<string>,
    },
    defaultQuery: {
      type: String as PropType<string>,
    },
    showQueryField: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    showOperatingSystemSelector: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  data() {
    return {
      os: this.defaultOs,
      query: this.defaultQuery,
    };
  },

  activated() {
    this.os = this.defaultOs;
  },

  computed: {
    hasExtensionManagerModel(): boolean {
      return hasExtensionManagerModel();
    },
    operatingSystems(): VueSelectItem[] {
      return [
        {
          text: 'Linux',
          value: OS.linux,
          icon: 'mdi-linux',
        },
        {
          text: 'macOS',
          value: OS.macosx,
          icon: 'mdi-apple',
        },
        {
          text: 'Windows',
          value: OS.windows,
          icon: 'mdi-microsoft-windows',
        },
      ];
    },
  },
});
