import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"loading":_vm.revisionByOperatingSystems.linux == undefined,"disabled":_vm.revisionByOperatingSystems.linux == undefined,"color":"success","to":{ name: 'Catalog', params: {
            category: _vm.category,
            revision: _vm.revisionByOperatingSystems.linux,
            os: 'linux',
          }}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Loading...")])]},proxy:true}])},[_vm._v(" Linux ")])],1)]),_c(VCol,[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"loading":_vm.revisionByOperatingSystems.macosx == undefined,"disabled":_vm.revisionByOperatingSystems.macosx == undefined,"color":"success","to":{ name: 'Catalog', params: {
            category: _vm.category,
            revision: _vm.revisionByOperatingSystems.macosx,
            os: 'macosx',
          }}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Loading...")])]},proxy:true}])},[_vm._v(" macOS ")])],1)]),_c(VCol,[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"ma-2",attrs:{"loading":_vm.revisionByOperatingSystems.win == undefined,"disabled":_vm.revisionByOperatingSystems.win == undefined,"color":"success","to":{ name: 'Catalog', params: {
            category: _vm.category,
            revision: _vm.revisionByOperatingSystems.win,
            os: 'win',
          }}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Loading...")])]},proxy:true}])},[_vm._v(" Windows ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }