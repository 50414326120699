import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hasExtensionManagerModel)?_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarTitle,[_vm._v("Slicer Extensions Manager")]),(_vm.showQueryField)?_c(VTextField,{staticClass:"shrink mx-4",attrs:{"hide-details":"","light":"","placeholder":"Type text...","prepend-inner-icon":"mdi-magnify","single-line":"","solo":"","text":"yes"},on:{"input":function($event){return _vm.$emit('update:query', $event);}},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}):_vm._e(),_c(VSpacer),(_vm.showOperatingSystemSelector)?_c(VSelect,{staticClass:"shrink mx-4",attrs:{"hide-details":"","solo":"","items":_vm.operatingSystems},on:{"change":function($event){return _vm.$emit('update:os', $event);}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(data.item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(data.item.text))])]}},{key:"item",fn:function(data){return [_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(data.item.icon))])],1),_c(VListItemTitle,[_vm._v(_vm._s(data.item.text))])]}}],null,false,2547556860),model:{value:(_vm.os),callback:function ($$v) {_vm.os=$$v},expression:"os"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }