
import Vue, { PropType } from 'vue';

import { getLatestPackageRevision, OS } from '@/lib/api/extension.service';

const AppId = process.env.VUE_APP_APP_ID as string;

interface OperatingSystemToRevisionMapping {
  linux: string | null;
  macosx: string | null;
  win: string | null;
}

export default Vue.extend({
  props: {
    category: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data() {
    return {
      revisionByOperatingSystems: {
        linux: null,
        macosx: null,
        win: null,
      } as OperatingSystemToRevisionMapping,
    };
  },

  methods: {
    async setRevisionByOperatingSystems() {
      this.revisionByOperatingSystems = {
        linux: await getLatestPackageRevision({ appId: AppId, os: OS.linux, arch: undefined }),
        macosx: await getLatestPackageRevision({ appId: AppId, os: OS.macosx, arch: undefined }),
        win: await getLatestPackageRevision({ appId: AppId, os: OS.windows, arch: undefined }),
      };
    },
  },

  activated() {
    this.setRevisionByOperatingSystems();
  },

});

