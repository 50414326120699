
import {
  computed, defineComponent, PropType, shallowRef, toRefs, watch,
} from '@vue/composition-api';
import {
  Extension, getExtension, OS, Arch,
} from '@/lib/api/extension.service';
import Bus from '@/plugins/bus';

import AppBar from '@/components/AppBar.vue';
import ActionButton from '@/components/ActionButton.vue';

const AppId = process.env.VUE_APP_APP_ID as string;
const ExtensionDefaultIconUrl = process.env.VUE_APP_EXTENSION_DEFAULT_ICON_URL as string;

export default defineComponent({
  props: {
    baseName: {
      type: String as PropType<string>,
      default: undefined,
    },
    revision: {
      type: String as PropType<string|undefined>,
      default: undefined,
    },
    os: {
      type: String as PropType<OS|undefined>,
      default: undefined,
    },
    arch: {
      type: String as PropType<Arch|undefined>,
      default: undefined,
    },
    id: {
      type: String as PropType<Arch|undefined>,
      default: undefined,
    },
    legacy: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  components: {
    ActionButton,
    AppBar,
  },

  setup(props, { root }) {
    const propsRefs = toRefs(props);
    const extension = shallowRef(null as Extension | null);

    async function loadExtension() {
      extension.value = await getExtension({
        appId: AppId,
        baseName: props.baseName,
        revision: props.revision ? parseInt(props.revision, 10) : undefined,
        os: props.os,
        arch: props.arch,
        id: props.id,
      });
    }

    loadExtension();

    Bus.$on('extension-state-updated', (extensionName: string) => {
      if (extensionName === extension.value?.title) {
        loadExtension();
      }
    });

    watch([
      propsRefs.baseName,
      propsRefs.revision,
      propsRefs.os,
      propsRefs.arch,
      propsRefs.id,
    ], loadExtension);

    const selectedOs = computed({
      get(): string {
        if (props.legacy) {
          return root.$route.query.os.toString();
        }
        return root.$route.params.os;
      },
      set(os: string): void {
        const { query } = root.$route;
        if (props.legacy) {
          root.$router.replace({ query: { ...query, os } }).catch((error: Error) => {
            if (error.name !== 'NavigationDuplicated') {
              throw error;
            }
          });
          return;
        }
        const location = { name: 'Extension Details', params: { os }, query };
        root.$router.push(location).catch((error: Error) => {
          if (error.name !== 'NavigationDuplicated') {
            throw error;
          }
        });
      },
    });

    const iconUrl = () => extension.value?.meta.icon_url || ExtensionDefaultIconUrl;

    const screenshotsAsList = () => {
      if (extension.value?.meta.screenshots) {
        return extension.value?.meta.screenshots.split(' ');
      }
      return undefined;
    };

    const hasOperatingSystemProp = computed(() => props.os !== undefined);

    const revisionUrl = () => {
      const strippedUrl = extension.value?.meta.repository_url.replace(/\.git$/, '');
      return `${strippedUrl}/commit/${extension.value?.meta.revision}`;
    };

    return {
      extension,
      hasOperatingSystemProp,
      iconUrl,
      revisionUrl,
      screenshotsAsList,
      selectedOs,
    };
  },
});
