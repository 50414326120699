import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarTitle,[_vm._v("Slicer DataStore")])],1),_c(VAlert,{attrs:{"color":"red","icon":"mdi-alert-circle-outline","outlined":"","prominent":"","text":""}},[_vm._v(" The DataStore module has been retired in March 2020. ")]),_c(VAlert,{attrs:{"color":"green","icon":"mdi-lightbulb-on-outline","outlined":"","prominent":"","text":"","type":"info"}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_vm._v(" Data files are now stored as release assets into the Slicer/SlicerDataStore GitHub project. ")]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"href":"https://github.com/Slicer/SlicerDataStore/releases/tag/SHA256"}},[_vm._v(" Browse Data ")])],1)],1)],1),_c(VAlert,{attrs:{"color":"blue-grey","icon":"mdi-school","outlined":"","prominent":"","text":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"grow"},[_vm._v(" Instructions for uploading additional datasets are documented on GitHub. ")]),_c(VCol,{staticClass:"shrink"},[_c(VBtn,{attrs:{"href":"https://github.com/Slicer/SlicerDataStore#readme"}},[_vm._v(" Read Instructions ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }