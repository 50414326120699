
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { Extension } from '@/lib/api/extension.service';

import ActionButton from '@/components/ActionButton.vue';

const ExtensionDefaultIconUrl = process.env.VUE_APP_EXTENSION_DEFAULT_ICON_URL as string;

export default Vue.extend({
  props: {
    extension: {
      type: Object as PropType<Extension>,
      required: true,
    },
    maxDescriptionLength: {
      type: Number as PropType<number>,
      default: 140,
    },
    legacy: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  components: { ActionButton },

  computed: {
    iconUrl(): string {
      return this.extension.meta.icon_url || ExtensionDefaultIconUrl;
    },
    description(): string {
      const { description } = this.extension.meta;
      if (description.length > this.maxDescriptionLength) {
        return description.slice(0, this.maxDescriptionLength).concat('...');
      }
      return this.extension.meta.description;
    },
    detailsRoute(): Location {
      const { currentRoute } = this.$router;
      if (this.legacy) {
        return {
          name: 'Extension Details Legacy',
          query: {
            baseName: this.extension.meta.baseName,
            ...currentRoute.query,
          },
        };
      }
      return {
        name: 'Extension Details',
        params: {
          baseName: this.extension.meta.baseName,
          ...currentRoute.params,
        },
      };
    },
  },
});
