
import Vue from 'vue';
import {
  hasExtensionManagerModel,
} from '@/lib/api/extension.service';

const BuildDate = process.env.VUE_APP_BUILD_DATE as string;
const GitHash = process.env.VUE_APP_GIT_HASH as string;

export default Vue.extend({
  computed: {
    hasExtensionManagerModel(): boolean {
      return hasExtensionManagerModel();
    },
    buildDate(): string {
      return BuildDate;
    },
    gitRevison(): string {
      return GitHash.slice(0, 7);
    },
    gitRevisionUrl(): string {
      return `https://github.com/KitwareMedical/slicer-extensions-webapp/commit/${GitHash}`;
    },
  },
});

